export const initialMappingTypeVal = {
  type: 'Placement',
};

export const initialMappingQuerySetupVal = {
  placement_setup: 'False',
  creative_setup: 'False',
};

export const initialVerifiedRender = {
  verified: 'verified',
};

export const initialMappingMode = {
  mode: 'edit',
};

export const initialManageListsMenuVal = {
  type: 'Lists',
};

export const initialCurrencyValue = {
  currencyState: {
    currencyName: 'British Pound',
    currencyAbb: 'gbp',
    currency: '£',
  },
};

export const initialChartConfigState = {
  chart: 'IMPRESSIONS',
};

export const initialGraphQuery = {
  measures: ['impressions'],
  dimensions: ['customer_name'],
  order: [
    ['week', 'asc'],
    ['impressions', 'desc'],
  ],
  period: ['latest90Days'],
  granularity: 'week',
  filters: [
    {
      dimension: 'advertiser',
      filterValues: [],
    },
    {
      dimension: 'channel',
      filterValues: [],
    },
    {
      dimension: 'device',
      filterValues: [],
    },
    {
      dimension: 'campaign_name',
      filterValues: [],
    },
    {
      dimension: 'country_name',
      filterValues: [],
    },
    {
      dimension: 'country_code',
      filterValues: [],
    },
    {
      dimension: 'portfolio',
      filterValues: [],
    },
    {
      dimension: 'product',
      filterValues: [],
    },
    {
      dimension: 'brand',
      filterValues: [],
    },
    {
      dimension: 'keyword',
      filterValues: [],
    },
    {
      dimension: 'keyword_type',
      filterValues: [],
    },
    {
      dimension: 'ad_name',
      filterValues: [],
    },
    {
      dimension: 'ad_group_name',
      filterValues: [],
    },
  ],
};

export const initialSumQuery = {
  measures: [
    'impressions',
    'views',
    'cost_gbp',
    'clicks',
    'cpc_gbp',
    'cpv_gbp',
    'cpm_gbp',
    'ctr',
    'vtr',
    /*     'video_views_25',
    'video_views_50',
    'video_views_75',
    'video_views_100', */
  ],
  period: ['latest90Days'],
  filters: [
    {
      dimension: 'advertiser',
      filterValues: [],
    },
    {
      dimension: 'channel',
      filterValues: [],
    },
    {
      dimension: 'device',
      filterValues: [],
    },
    {
      dimension: 'campaign_name',
      filterValues: [],
    },
    {
      dimension: 'country_name',
      filterValues: [],
    },
    {
      dimension: 'country_code',
      filterValues: [],
    },
    {
      dimension: 'portfolio',
      filterValues: [],
    },
    {
      dimension: 'product',
      filterValues: [],
    },
    {
      dimension: 'brand',
      filterValues: [],
    },
    {
      dimension: 'keyword',
      filterValues: [],
    },
    {
      dimension: 'keyword_type',
      filterValues: [],
    },
    {
      dimension: 'ad_name',
      filterValues: [],
    },
    {
      dimension: 'ad_group_name',
      filterValues: [],
    },
  ],
};

export const initialTableQuery = {
  measures: [
    'impressions',
    'views',
    'cost_gbp',
    'clicks',
    'cpc_gbp',
    'cpv_gbp',
    'cpm_gbp',
    'ctr',
    'vtr',
    'video_views_25',
    'video_views_50',
    'video_views_75',
    'video_views_100',
  ],
  period: ['latest90Days'],
  dimensions: [],
  granularity: 'week',
  order: [['week', 'desc']],
  filters: [
    {
      dimension: 'advertiser',
      filterValues: [],
    },
    {
      dimension: 'channel',
      filterValues: [],
    },
    {
      dimension: 'device',
      filterValues: [],
    },
    {
      dimension: 'campaign_name',
      filterValues: [],
    },
    {
      dimension: 'country_name',
      filterValues: [],
    },
    {
      dimension: 'country_code',
      filterValues: [],
    },
    {
      dimension: 'portfolio',
      filterValues: [],
    },
    {
      dimension: 'product',
      filterValues: [],
    },
    {
      dimension: 'brand',
      filterValues: [],
    },
    {
      dimension: 'keyword',
      filterValues: [],
    },
    {
      dimension: 'keyword_type',
      filterValues: [],
    },
    {
      dimension: 'ad_name',
      filterValues: [],
    },
    {
      dimension: 'ad_group_name',
      filterValues: [],
    },
  ],
  limit: 5000,
};

export const initialReportQuery = {
  measures: [
    'impressions',
    'views',
    'cost_gbp',
    'clicks',
    'cpc',
    'cpv',
    'cpm',
    'ctr',
    'vtr',
  ],
  period: ['latest90Days'],
  dimensions: [
    'advertiser',
    'channel',
    'device',
    'campaign_name',
    'country_name',
    'country_code',
    'portfolio',
    'product',
    'brand',
    'keyword',
  ],
  granularity: 'week',
  order: [['week', 'desc']],
  filters: [
    {
      dimension: 'advertiser',
      filterValues: [],
    },
    {
      dimension: 'channel',
      filterValues: [],
    },
    {
      dimension: 'device',
      filterValues: [],
    },
    {
      dimension: 'campaign_name',
      filterValues: [],
    },
    {
      dimension: 'country_name',
      filterValues: [],
    },
    {
      dimension: 'country_code',
      filterValues: [],
    },
    {
      dimension: 'portfolio',
      filterValues: [],
    },
    {
      dimension: 'product',
      filterValues: [],
    },
    {
      dimension: 'brand',
      filterValues: [],
    },
    {
      dimension: 'keyword',
      filterValues: [],
    },
    {
      dimension: 'keyword_type',
      filterValues: [],
    },
    {
      dimension: 'ad_name',
      filterValues: [],
    },
    {
      dimension: 'ad_group_name',
      filterValues: [],
    },
  ],
  limit: 5000,
};

export const initialPlacementMapping = {
  mainState: [
    {
      column_name: 'mapping_id',
      column_type: 'row_id',
      default_value: '',
      display_name: 'mapping_id',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: false,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'user_column_50',
      column_type: 'string',
      default_value: '',
      display_name: 'Alias',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'customer_name',
      column_type: 'list',
      default_value: '',
      display_name: 'customer_name',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Client',
    },
    {
      column_name: 'user_column_1',
      column_type: 'taxonomy_id',
      default_value: '',
      display_name: 'Agency_Placement_ID',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'campaign_name',
      column_type: 'taxonomy_string',
      default_value: '',
      display_name: 'Placement_String',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'user_column_2',
      column_type: 'list',
      default_value: '',
      display_name: 'Campaign',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Campaign',
    },
    {
      column_name: 'user_column_3',
      column_type: 'list',
      default_value: '',
      display_name: 'Initiative',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Initiative',
    },
    {
      column_name: 'user_column_4',
      column_type: 'list',
      default_value: '',
      display_name: 'Partner_Type',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Partner Type',
    },
    {
      column_name: 'user_column_5',
      column_type: 'list',
      default_value: '',
      display_name: 'Partner_Name',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Partner',
    },
    {
      column_name: 'user_column_6',
      column_type: 'list',
      default_value: '',
      display_name: 'Targeting_Tactic',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Targeting Tactic',
    },
    {
      column_name: 'user_column_7',
      column_type: 'list',
      default_value: '',
      display_name: 'Targeting_Name',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Targeting Name',
    },
    {
      column_name: 'user_column_8',
      column_type: 'list',
      default_value: '',
      display_name: 'Buy_Type',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Buy Type',
    },
    {
      column_name: 'user_column_9',
      column_type: 'string',
      default_value: '',
      display_name: 'Region',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'country',
      column_type: 'list',
      default_value: '',
      display_name: 'country',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Country',
    },
    {
      column_name: 'user_column_10',
      column_type: 'list',
      default_value: '',
      display_name: 'Language',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Language',
    },
    {
      column_name: 'source_system',
      column_type: 'list',
      default_value: '',
      display_name: 'Device',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Device',
    },
    {
      column_name: 'user_column_11',
      column_type: 'list',
      default_value: '',
      display_name: 'Operating_System',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Operating System',
    },
    {
      column_name: 'user_column_12',
      column_type: 'list',
      default_value: '',
      display_name: 'Landing_Page',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Landing Page',
    },

    {
      column_name: 'user_column_13',
      column_type: 'list',
      default_value: '',
      display_name: 'Placement_Launch_Quarter',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Placement Launch Quarter',
    },
    {
      column_name: 'user_column_14',
      column_type: 'list',
      default_value: '',
      display_name: 'Placement_Launch_Year',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Placement Launch Year',
    },
    {
      column_name: 'user_column_15',
      column_type: 'list',
      default_value: '',
      display_name: 'Serving_Type',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Serving Type',
    },

    {
      column_name: 'user_column_16',
      column_type: 'list',
      default_value: '',
      display_name: 'Audience_Verification',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Audience Verification',
    },
    {
      column_name: 'user_column_17',
      column_type: 'list',
      default_value: '',
      display_name: 'Ad_Format',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Ad Format',
    },
    {
      column_name: 'user_column_18',
      column_type: 'list',
      default_value: '',
      display_name: 'Placement_Size',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Placement Size',
    },
    {
      column_name: 'user_column_19',
      column_type: 'list',
      default_value: '',
      display_name: 'Biddable_Objective',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Biddable Objective',
    },
    {
      column_name: 'user_column_20',
      column_type: 'list',
      default_value: '',
      display_name: 'Biddable_Placement',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Biddable Placement',
    },
    {
      column_name: 'user_column_21',
      column_type: 'list',
      default_value: '',
      display_name: 'Age',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Age',
    },
    {
      column_name: 'user_column_22',
      column_type: 'list',
      default_value: '',
      display_name: 'Gender',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Gender',
    },
    {
      column_name: 'user_column_23',
      column_type: 'list',
      default_value: '',
      display_name: 'Bid_Type',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Bid Type',
    },
    {
      column_name: 'user_column_24',
      column_type: 'list',
      default_value: '',
      display_name: 'Bidding_Window',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Bidding Window',
    },
    {
      column_name: 'user_column_25',
      column_type: 'list',
      default_value: '',
      display_name: 'Optimisation_Goal',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Optimisation Goal',
    },
    {
      column_name: 'user_column_26',
      column_type: 'list',
      default_value: '',
      display_name: 'Testing',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Testing',
    },
    {
      column_name: 'user_column_27',
      column_type: 'list',
      default_value: '',
      display_name: 'Audience_Source',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Audience Source',
    },
    {
      column_name: 'user_column_28',
      column_type: 'list',
      default_value: '',
      display_name: 'Carrier',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Carrier',
    },
    {
      column_name: 'verified',
      column_type: 'utility',
      default_value: '',
      display_name: 'verified',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'compliant',
      column_type: 'utility',
      default_value: '',
      display_name: 'compliant',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'created_by',
      column_type: 'utility',
      default_value: '',
      display_name: 'created_by',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'created_ts',
      column_type: 'utility',
      default_value: '',
      display_name: 'created_ts',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'last_updated_by',
      column_type: 'utility',
      default_value: '',
      display_name: 'last_updated_by',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'last_updated_ts',
      column_type: 'utility',
      default_value: '',
      display_name: 'last_updated_ts',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
  ],
};

export const initialCreativeMapping = {
  mainState: [
    {
      column_name: 'mapping_id',
      column_type: 'row_id',
      default_value: '',
      display_name: 'mapping_id',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: false,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'user_column_50',
      column_type: 'string',
      default_value: '',
      display_name: 'Alias',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'customer_name',
      column_type: 'string',
      default_value: '',
      display_name: 'customer_name',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: 'Client',
    },
    {
      column_name: 'campaign_name',
      column_type: 'taxonomy_id',
      default_value: '',
      display_name: 'Creative_ID',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'user_column_13',
      column_type: 'taxonomy_string',
      default_value: '',
      display_name: 'Creative_String',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'user_column_1',
      column_type: 'list',
      default_value: '',
      display_name: 'Creative_Concept',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Creative Concept',
    },
    {
      column_name: 'user_column_2',
      column_type: 'list',
      default_value: '',
      display_name: 'Image',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Image',
    },
    {
      column_name: 'user_column_3',
      column_type: 'list',
      default_value: '',
      display_name: 'Copy_Headline',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Copy / Headline',
    },
    {
      column_name: 'user_column_4',
      column_type: 'list',
      default_value: '',
      display_name: 'CTA',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'CTA',
    },
    {
      column_name: 'user_column_5',
      column_type: 'list',
      default_value: '',
      display_name: 'Ad_Language',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Ad Language',
    },
    {
      column_name: 'user_column_6',
      column_type: 'list',
      default_value: '',
      display_name: 'unit_type',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Unit Type',
    },
    {
      column_name: 'user_column_7',
      column_type: 'list',
      default_value: '',
      display_name: 'Long_Description',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Long Description',
    },
    {
      column_name: 'user_column_8',
      column_type: 'list',
      default_value: '',
      display_name: 'Orientation',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Orientation',
    },
    {
      column_name: 'user_column_9',
      column_type: 'list',
      default_value: '',
      display_name: 'Creative_Unit_Size',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Creative Unit Size',
    },
    {
      column_name: 'user_column_10',
      column_type: 'list',
      default_value: '',
      display_name: 'Audio',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Audio',
    },
    {
      column_name: 'user_column_11',
      column_type: 'list',
      default_value: '',
      display_name: 'Subtitles',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Subtitles',
    },
    {
      column_name: 'user_column_12',
      column_type: 'list',
      default_value: '',
      display_name: 'Landing_Page',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: true,
      list_name: 'Landing Page',
    },
    {
      column_name: 'verified',
      column_type: 'utility',
      default_value: '',
      display_name: 'verified',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'compliant',
      column_type: 'utility',
      default_value: '',
      display_name: 'compliant',
      user_defined_name: '',
      is_editable: true,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'created_by',
      column_type: 'utility',
      default_value: '',
      display_name: 'created_by',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'created_ts',
      column_type: 'utility',
      default_value: '',
      display_name: 'created_ts',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'last_updated_by',
      column_type: 'utility',
      default_value: '',
      display_name: 'last_updated_by',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
    {
      column_name: 'last_updated_ts',
      column_type: 'utility',
      default_value: '',
      display_name: 'last_updated_ts',
      user_defined_name: '',
      is_editable: false,
      is_mandatory: false,
      is_visible: true,
      can_override_list: false,
      list_name: '',
    },
  ],
};

export const initialDataHealthGraphQuery = {
  measures: ['count'],
  dimensions: ['source_system'],
  order: [
    ['day', 'asc'],
    ['source_system', 'asc'],
  ],
  period: ['latest90Days'],
  granularity: 'day',
  filters: [
    {
      dimension: 'advertiser',
      filterValues: [],
    },
    {
      dimension: 'channel',
      filterValues: [],
    },
    {
      dimension: 'device',
      filterValues: [],
    },
    {
      dimension: 'campaign_name',
      filterValues: [],
    },
    {
      dimension: 'country_name',
      filterValues: [],
    },
    {
      dimension: 'country_code',
      filterValues: [],
    },
    {
      dimension: 'portfolio',
      filterValues: [],
    },
    {
      dimension: 'product',
      filterValues: [],
    },
    {
      dimension: 'brand',
      filterValues: [],
    },
    {
      dimension: 'keyword',
      filterValues: [],
    },
    {
      dimension: 'keyword_type',
      filterValues: [],
    },
    {
      dimension: 'ad_name',
      filterValues: [],
    },
    {
      dimension: 'ad_group_name',
      filterValues: [],
    },
  ],
};

export const initialComplianceScoreQuery = {
  measures: ['cat_cost'],
  dimensions: ['combination_index'],
  order: [
    ['day', 'asc'],
    ['combination_index', 'asc'],
  ],
  period: ['latest90Days'],
  granularity: 'day',
  filters: [
    {
      dimension: 'source_system',
      filterValues: [],
    },
    {
      dimension: 'combination_index',
      filterValues: [],
    },
    {
      dimension: 'compliant',
      filterValues: [],
    },
    {
      dimension: 'verified',
      filterValues: [],
    },
  ],
};
