import { createSlice } from '@reduxjs/toolkit';
import { initialSumQuery } from 'redux/reduxInitialStates';

const initialState = initialSumQuery;

export const sumQuerySlice = createSlice({
  name: 'sumQuery',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateSumFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateSumMeasures: (state, action) => {
      state.measures = action.payload;
    },
    updateSumTimeDimensionTimeRange: (state, action) => {
      state.period = action.payload;
    },
  },
});

export const {
  revertToInitialState,
  updateSumFilters,
  updateSumTimeDimensionTimeRange,
  updateSumMeasures,
} = sumQuerySlice.actions;

export const selectSumQuery = (state) => {
  return state.sumQuery;
};

export default sumQuerySlice.reducer;
