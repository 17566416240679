import { createSlice } from '@reduxjs/toolkit';
import { initialCurrencyValue } from 'redux/reduxInitialStates';

const initialState = initialCurrencyValue;

export const currencySlice = createSlice({
  name: 'currencyValue',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateCurrencyState: (state, action) => {
      state.currencyState = action.payload;
    },
  },
});

export const { revertToInitialState, updateCurrencyState } =
  currencySlice.actions;

export const selectCurrency = (state) => {
  return state.currency;
};

export default currencySlice.reducer;
