import { createSlice } from '@reduxjs/toolkit';
import { initialChartConfigState } from 'redux/reduxInitialStates';

const initialState = initialChartConfigState;

export const chartConfigStateSlice = createSlice({
  name: 'chartConfigState',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateChartConfigState: (state, action) => {
      state.chart = action.payload;
    },
  },
});

export const { revertToInitialState, updateChartConfigState } =
  chartConfigStateSlice.actions;

export const selectChartState = (state) => {
  return state.chart;
};

export default chartConfigStateSlice.reducer;
