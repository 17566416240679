import { Link } from 'react-router-dom';
import ChevronDownIcon from '../../components/shared/icons/ChevronDown';

const NavigationItems = ({
  login,
  navData,
  location,
  addNewCampaign,
  setupDropdownRef,
  setAddNewCampaign,
  showImportCsvModal,
  isSetupDropdownOpen,
  setShowImportCsvModal,
  setIsSetupDropdownOpen,
  qualityDropdownRef,
  reportingDropdownRef,
  isReportingDropdownOpen,
  setIsReportingDropdownOpen,
  isProfileOpen,
  setIsProfileOpen,
  isViewAsOpen,
  setIsViewAsOpen,
  setIsQualityDropdownOpen,
  isQualityDropdownOpen,
  showImportConfirmationModal,
  setShowImportConfirmationModal,
}) => {
  return (
    <nav className='topnav-navigation'>
      {/* DATA QUALITY */}

      {navData.dataQuality.readPermissions.includes(
        login.permissions.find((perm) => {
          return perm.startsWith('Role.');
        })
      ) && (
        <div style={{ display: 'flex' }}>
          <div className='dd' ref={qualityDropdownRef}>
            <div
              className='dd-setup_toggle'
              onClick={() => {
                isSetupDropdownOpen && setIsSetupDropdownOpen(false);
                isReportingDropdownOpen && setIsReportingDropdownOpen(false);
                isProfileOpen && setIsProfileOpen(false);
                isViewAsOpen && setIsViewAsOpen(false);
                setIsQualityDropdownOpen(!isQualityDropdownOpen);
              }}
            >
              <div
                className='h-100'
                style={{
                  background: isQualityDropdownOpen ? '#232751' : null,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  padding: '.5rem',
                }}
              >
                <span
                  className='dd-setup_label'
                  style={{
                    color: 'white',
                    fontWeight: isQualityDropdownOpen ? 'bold' : null,
                  }}
                >
                  Quality
                </span>

                <span>
                  <ChevronDownIcon stroke='#BDBDBD' />
                </span>
              </div>
            </div>
            {isQualityDropdownOpen && (
              <div className='dd-setup_menu overflow-style'>
                {navData.dataHealth.readPermissions.includes(
                  login.permissions.find((perm) => {
                    return perm.startsWith('Role.');
                  })
                ) && (
                  <Link
                    onClick={() => {
                      if (isQualityDropdownOpen === true) {
                        setIsQualityDropdownOpen(false);
                      }
                    }}
                    className='dd-setup_item'
                    to='/data-health'
                  >
                    Data Health
                  </Link>
                )}
                {navData.dataCompliance.readPermissions.includes(
                  login.permissions.find((perm) => {
                    return perm.startsWith('Role.');
                  })
                ) && (
                  <Link
                    onClick={() => {
                      if (isQualityDropdownOpen === true) {
                        setIsQualityDropdownOpen(false);
                      }
                    }}
                    className='dd-setup_item'
                    to='/data-compliance'
                  >
                    Data Compliance
                  </Link>
                )}
                {navData.dataExplore.readPermissions.includes(
                  login.permissions.find((perm) => {
                    return perm.startsWith('Role.');
                  })
                ) && (
                  <Link
                    onClick={() => {
                      if (isQualityDropdownOpen === true) {
                        setIsQualityDropdownOpen(false);
                      }
                    }}
                    className='dd-setup_item'
                    to='/data-explore'
                  >
                    Data Explore
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {navData?.mappings?.readPermissions.includes(
        login.permissions.find((perm) => {
          return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
        })
      ) && (
        <Link
          className='topnav-navigation_item h-100'
          style={{
            borderBottom:
              location.pathname.startsWith('/mappings') &&
              '2px solid var(--intuita-main)',
            fontWeight: location.pathname.startsWith('/mappings') && 'bold',
          }}
          onClick={() => {
            if (addNewCampaign) setAddNewCampaign(false);
            if (showImportCsvModal) setShowImportCsvModal(false);
            if (showImportConfirmationModal)
              setShowImportConfirmationModal(false);
          }}
          to='/mappings'
        >
          Mappings
        </Link>
      )}
      {navData?.taxonomy?.readPermissions.includes(
        login.permissions.find((perm) => {
          return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
        })
      ) && (
        <Link
          className='topnav-navigation_item'
          style={{
            borderBottom:
              location.pathname.startsWith('/taxonomies') &&
              '2px solid var(--intuita-main)',
            fontWeight: location.pathname.startsWith('/taxonomies') && 'bold',
          }}
          onClick={() => {
            if (showImportCsvModal) setShowImportCsvModal(false);
          }}
          to='/taxonomies'
        >
          Taxonomies
        </Link>
      )}
      {navData?.lists?.readPermissions.includes(
        login.permissions.find((perm) => {
          return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
        })
      ) && (
        <Link
          className='topnav-navigation_item'
          style={{
            borderBottom:
              location.pathname.startsWith('/lists') &&
              '2px solid var(--intuita-main)',
            fontWeight: location.pathname.startsWith('/lists') && 'bold',
          }}
          onClick={() => {
            if (showImportCsvModal) setShowImportCsvModal(false);
          }}
          to='/lists'
        >
          Lists
        </Link>
      )}
      {(navData.reports.readPermissions.includes(
        login.permissions.find((perm) => {
          return perm.startsWith('Role.');
        })
      ) ||
        navData.dashboard.readPermissions.includes(
          login.permissions.find((perm) => {
            return perm.startsWith('Role.');
          })
        ) ||
        navData.performance.readPermissions.includes(
          login.permissions.find((perm) => {
            return perm.startsWith('Role.');
          })
        )) && (
        <div style={{ display: 'flex' }}>
          <div className='dd' ref={reportingDropdownRef}>
            <div
              className='dd-setup_toggle'
              onClick={() => {
                isSetupDropdownOpen && setIsSetupDropdownOpen(false);
                isQualityDropdownOpen && setIsQualityDropdownOpen(false);
                isProfileOpen && setIsProfileOpen(false);
                isViewAsOpen && setIsViewAsOpen(false);
                setIsReportingDropdownOpen(!isReportingDropdownOpen);
              }}
            >
              <div
                className='h-100'
                style={{
                  background: isReportingDropdownOpen ? '#232751' : null,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  padding: '.5rem',
                }}
              >
                <span
                  className='dd-setup_label'
                  style={{
                    color: 'white',
                    fontWeight: isReportingDropdownOpen ? 'bold' : null,
                  }}
                >
                  Reporting
                </span>

                <span>
                  {isReportingDropdownOpen ? (
                    <ChevronDownIcon stroke='#BDBDBD' />
                  ) : (
                    <ChevronDownIcon stroke='#BDBDBD' />
                  )}
                </span>
              </div>
            </div>
            {isReportingDropdownOpen && (
              <div className='dd-setup_menu overflow-style'>
                {navData.reports.readPermissions.includes(
                  login.permissions.find((perm) => {
                    return perm.startsWith('Role.');
                  })
                ) && (
                  <Link
                    onClick={() => {
                      if (isReportingDropdownOpen === true) {
                        setIsReportingDropdownOpen(false);
                      }
                    }}
                    className='dd-setup_item'
                    to='/performance-report'
                  >
                    Performance Report
                  </Link>
                )}

                {navData.performance.readPermissions.includes(
                  login.permissions.find((perm) => {
                    return perm.startsWith('Role.');
                  })
                ) && (
                  <Link
                    onClick={() => {
                      if (isReportingDropdownOpen === true) {
                        setIsReportingDropdownOpen(false);
                      }
                    }}
                    className='dd-setup_item'
                    to='/campaign-performance'
                  >
                    Campaign Performance
                  </Link>
                )}
                {navData.dashboard.readPermissions.includes(
                  login.permissions.find((perm) => {
                    return perm.startsWith('Role.');
                  })
                ) && (
                  <Link
                    onClick={() => {
                      if (isReportingDropdownOpen === true) {
                        setIsReportingDropdownOpen(false);
                      }
                    }}
                    className='dd-setup_item'
                    to='/dashboards'
                  >
                    Dashboards
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {(navData?.clientManagement?.readPermissions.includes(
        login.permissions.find((perm) => {
          return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
        })
      ) ||
        navData?.tableDefinitions?.readPermissions.includes(
          login.permissions.find((perm) => {
            return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
          })
        )) && (
        <div className='dd' ref={setupDropdownRef}>
          <div
            className='dd-setup_toggle'
            onClick={() => {
              setIsSetupDropdownOpen(!isSetupDropdownOpen);
            }}
          >
            <div
              style={{
                background: isSetupDropdownOpen ? '#232751' : null,
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                padding: '.5rem',
                height: '100%',
              }}
            >
              <span
                className='dd-setup_label'
                style={{
                  color: 'white',
                  fontWeight: isSetupDropdownOpen ? 'bold' : null,
                }}
              >
                Setup
              </span>

              <span>
                <ChevronDownIcon stroke='#BDBDBD' />
              </span>
            </div>
          </div>
          {isSetupDropdownOpen && (
            <div className='dd-setup_menu overflow-style'>
              {navData?.clientManagement?.readPermissions.includes(
                login.permissions.find((perm) => {
                  return (
                    perm.startsWith('Role.') || perm.startsWith('eu-west-2')
                  );
                })
              ) && (
                <Link
                  onClick={() => {
                    if (showImportCsvModal) setShowImportCsvModal(false);
                    if (isSetupDropdownOpen === true) {
                      setIsSetupDropdownOpen(false);
                    }
                  }}
                  className='dd-setup_item'
                  to='/client-management'
                >
                  Client Management
                </Link>
              )}
              {navData?.tableDefinitions?.readPermissions.includes(
                login.permissions.find((perm) => {
                  return (
                    perm.startsWith('Role.') || perm.startsWith('eu-west-2')
                  );
                })
              ) && (
                <Link
                  onClick={() => {
                    if (showImportCsvModal) setShowImportCsvModal(false);
                    if (isSetupDropdownOpen === true) {
                      setIsSetupDropdownOpen(false);
                    }
                  }}
                  className='dd-setup_item'
                  to='/table-definitions'
                >
                  Mapping Setup
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default NavigationItems;
