import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/themes/translucent.css';
import 'tippy.js/dist/tippy.css';

const Tooltip = ({ theme, content, placement, className, arrow, children }) => {
  return (
    <Tippy
      theme='light-border'
      className={className}
      content={content}
      arrow={arrow}
      placement={placement}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
