import { gql } from '@apollo/client';

//
//
//
//
// LISTS
//
//
//
//
//
export const GET_S2_LIST_NAMES_STRING = 'GET_S2_LIST_NAMES';
export const GET_S2_LIST_GLOBAL_NAMES_STRING = 'GET_S2_LIST_GLOBAL_NAMES';
export const GET_S2_LIST_CLIENT_NAMES_STRING = 'GET_S2_LIST_CLIENT_NAMES';
export const GET_S2_LIST_VALUES_STRING = 'GET_S2_LIST_VALUES';
export const GET_S2_LIST_GLOBAL_VALUES_STRING = 'GET_S2_LIST_GLOBAL_VALUES';
export const GET_S2_LIST_CLIENT_VALUES_STRING = 'GET_S2_LIST_CLIENT_VALUES';
export const GET_S2_OTHER_LIST_NAMES_STRING = 'GET_S2_OTHER_LIST_NAMES';
export const GET_S2_OTHER_GLOBAL_LIST_NAMES_STRING =
  'GET_S2_OTHER_GLOBAL_LIST_NAMES';
export const GET_S2_OTHER_CLIENT_LIST_NAMES_STRING =
  'GET_S2_OTHER_CLIENT_LIST_NAMES';
export const GET_S2_LIST_DATA_BY_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_BY_LIST_NAME';
export const GET_S2_LIST_DATA_GLOBAL_BY_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_GLOBAL_BY_LIST_NAME';
export const GET_S2_LIST_DATA_CLIENT_BY_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_CLIENT_BY_LIST_NAME';
export const GET_S2_LIST_DATA_WITHOUT_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_WITHOUT_LIST_NAME';
export const GET_S2_LIST_DATA_GLOBAL_WITHOUT_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_GLOBAL_WITHOUT_LIST_NAME';
export const GET_S2_LIST_DATA_CLIENT_WITHOUT_LIST_NAME_STRING =
  'GET_S2_LIST_DATA_CLIENT_WITHOUT_LIST_NAME';
//list_order_sequence
export const GET_S2_GLOBAL_LIST_VALUES = gql`
  query GetListValues($mapping_type: String = "") {
    s2_lists(
      where: { _eq: { mapping_type: $mapping_type, client_name: "Global" } }
      orderBy: { list_value: asc }
    ) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name

      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

export const GET_S2_LIST_DATA_BY_LIST_NAME_AND_CLIENT_NAME = gql`
  query GetListValuesByName(
    $list_name: String = ""
    $client_name: String = ""
    $mapping_type: String = ""
  ) {
    s2_lists(
      where: {
        _eq: {
          list_name: $list_name
          mapping_type: $mapping_type
          client_name: $client_name
        }
      }
      orderBy: { list_value: asc }
    ) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name

      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

export const GET_S2_LIST_DATA_BY_LIST_NAME = gql`
  query GetListValuesByName(
    $list_name: String = ""
    $mapping_type: String = ""
  ) {
    s2_lists(
      where: { _eq: { list_name: $list_name, mapping_type: $mapping_type } }
      orderBy: { list_value: asc }
    ) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name

      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

export const GET_S2_GLOBAL_LIST_DATA_BY_LIST_NAME = gql`
  query GetListValuesByName(
    $list_name: String = ""
    $mapping_type: String = ""
  ) {
    s2_lists(
      where: {
        _eq: {
          list_name: $list_name
          mapping_type: $mapping_type
          client_name: "Global"
        }
      }
      orderBy: { list_value: asc }
    ) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name

      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

export const GET_S2_LIST_VALUES = gql`
  query GetListValues($mapping_type: String = "") {
    s2_lists(
      where: { _eq: { mapping_type: $mapping_type } }
      orderBy: { list_value: asc }
    ) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name

      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

//
//
//
//
// TAXONOMIES
//
//
//
//
//
export const GET_S2_TAXONOMIES_STRING = 'GET_S2_TAXONOMIES';

export const GET_S2_TAXONOMY_INDEX_HIGHEST_STRING =
  'GET_S2_TAXONOMY_INDEX_HIGHEST';

export const GET_S2_TAXONOMY_BY_PREFIX_STRING = 'GET_S2_TAXONOMY_BY_PREFIX';
export const GET_S2_GLOBAL_TAXONOMIES_STRING = 'GET_S2_GLOBAL_TAXONOMIES';
export const GET_S2_CLIENT_TAXONOMIES_STRING = 'GET_S2_CLIENT_TAXONOMIES';

export const GET_S2_GLOBAL_TAXONOMIES = gql`
  query S2_taxonomies($mapping_type: String = "", $client_name: String = "") {
    s2_taxonomies(
      where: { _eq: { mapping_type: $mapping_type, client_name: $client_name } }
    ) {
      taxonomy_string
      taxonomy_name
      taxonomy_id
      taxonomy_description
      taxonomy_prefix
      mapping_type
      last_updated_ts
      last_updated_by
      created_ts
      created_by
      client_name
      visible
    }
  }
`;

//REWORK
export const GET_S2_TAXONOMIES = gql`
  query S2_taxonomies($mapping_type: String = "") {
    s2_taxonomies(where: { _eq: { mapping_type: $mapping_type } }) {
      taxonomy_string
      taxonomy_name
      taxonomy_id
      taxonomy_description
      taxonomy_prefix
      mapping_type
      last_updated_ts
      last_updated_by
      created_ts
      created_by
      client_name
      tax_author
      visible
    }
  }
`;

export const GET_S2_TAXONOMY_INDEX_HIGHEST = gql`
  query S2_taxonomies {
    s2_taxonomies(orderBy: { taxonomy_id: desc }, limit: 1) {
      taxonomy_id
    }
  }
`;

export const GET_S2_TAXONOMY_BY_PREFIX = gql`
  query S2_taxonomies(
    $mapping_type: String = ""
    $taxonomy_prefix: String = ""
  ) {
    s2_taxonomies(
      where: {
        _eq: { mapping_type: $mapping_type, taxonomy_prefix: $taxonomy_prefix }
      }
    ) {
      taxonomy_string
      taxonomy_name
      taxonomy_id
      taxonomy_description
      taxonomy_prefix
      mapping_type
      last_updated_ts
      last_updated_by
      created_ts
      created_by
      client_name
      visible
      tax_author
    }
  }
`;

//
//
//
//
// TAXONOMY SEQUENCES
//
//
//
//
//
export const GET_S2_DATADQ_SEQUENCES_STRING = 'GET_S2_DATADQ_SEQUENCES';
export const GET_S2_DATADQ_SEQUENCES_BY_PREFIX_STRING =
  'GET_S2_DATADQ_SEQUENCES_BY_PREFIX';
export const GET_S2_HIGHEST_DATADQ_SEQUENCE_ID_STRING =
  'GET_S2_HIGHEST_DATADQ_SEQUENCE_ID';
export const GET_S2_GLOBAL_DATADQ_SEQUENCES_STRING =
  'GET_S2_GLOBAL_DATADQ_SEQUENCES';
export const GET_S2_CLIENT_DATADQ_SEQUENCES_STRING =
  'GET_S2_CLIENT_DATADQ_SEQUENCES';
//REWORK
export const GET_S2_DATADQ_SEQUENCES = gql`
  query S2_datadq_sequence(
    $mapping_type: String = ""
    $client_name: String = ""
  ) {
    s2_datadq_sequence(
      where: { _eq: { mapping_type: $mapping_type, client_name: $client_name } }
    ) {
      sequence_id
      sequence_name
      sequence_prefix
      sequence_suffix
      sequence_numeric
      sequence_increment
      sequence_num_of_digits
      mapping_type
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      client_name
      visible
      tax_author
    }
  }
`;

export const GET_S2_HIGHEST_DATADQ_SEQUENCE_ID = gql`
  query S2_datadq_sequence {
    s2_datadq_sequence(orderBy: { sequence_id: desc }, limit: 1) {
      sequence_id
    }
  }
`;

export const GET_S2_DATADQ_SEQUENCES_BY_PREFIX = gql`
  query S2_datadq_sequence(
    $prefix: String = ""
    $mapping_type: String = ""
    $client_name: String = ""
  ) {
    s2_datadq_sequence(
      where: {
        _eq: {
          mapping_type: $mapping_type
          sequence_prefix: $prefix
          client_name: $client_name
        }
      }
    ) {
      sequence_id
      sequence_name
      sequence_prefix
      sequence_suffix
      sequence_numeric
      sequence_increment
      sequence_num_of_digits
      mapping_type
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      client_name
      visible
      tax_author
    }
  }
`;

//
// NOTIFICATIONS
//

export const GET_S2_USER_NOTIFICATIONS = gql`
  query Get_s2_user_notifications($_limit: Int, $_user: String) {
    user_notifications(args: { _limit: $_limit, _user: $_user }) {
      action
      client_name
      created_by
      created_ts
      duration
      expire_dts
      last_updated_by
      notification_text
      notification_user
      notification_read
      notification_id
      list_name
      last_updated_ts
      status
      start_dts
    }
  }
`;

export const GET_RELATIONSHIPS_GLOBAL_STRING = 'GET_RELATIONSHIPS_GLOBAL';
export const GET_RELATIONSHIPS_STRING = 'GET_RELATIONSHIPS';

//client_name
export const GET_RELATIONSHIPS = gql`
  query Get_relationships($tableType: String, $where: S2ListsWhereInput) {
    get_relationships(
      table_type: $tableType
      where: $where
      orderBy: { sequence_no: asc }
    ) {
      client_name
      column_name
      column_type
      default_value
      display_name
      user_defined_name
      is_editable
      is_mandatory
      is_visible
      can_override_list
      list_name
      mapping_type
      table_id
      row_id
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      sequence_no
      visible
      no_blanks
      max_char_length
      distinct
      is_number
      exceptions
    }
  }
`;

export const GET_HISTORY = gql`
  query Get_history($rowId: Int) {
    get_history(row_id: $rowId) {
      audit_ts
      row_id
      audit_user
      audit_count
    }
  }
`;

export const GET_HISTORY_DETAIL = gql`
  query Get_history_detail($rowId: Int) {
    get_history_detail(row_id: $rowId) {
      audit_ts
      audit_user
      column_name
      cell_value
      prev_cell_value
    }
  }
`;

export const GET_COLUMN_WIDTH = gql`
  query Get_column_width(
    $mappingType: String
    $clientName: String
    $tableType: String
  ) {
    get_column_width(
      mapping_type: $mappingType
      client_name: $clientName
      table_type: $tableType
    ) {
      column
      width
      cell_id
    }
  }
`;

export const SUBSCRIBE_S2_USER_NOTIFICATIONS = gql`
  subscription Subscription_s2_user_notifications(
    $_limit: Int
    $_user: String
    $client_name: String
  ) {
    user_notifications(
      args: { _limit: $_limit, _user: $_user }
      where: { client_name: { _eq: $client_name } }
    ) {
      action
      client_name
      created_by
      created_ts
      duration
      expire_dts
      last_updated_by
      notification_text
      notification_user
      notification_read
      notification_id
      list_name
      last_updated_ts
      status
      start_dts
    }
  }
`;
