import { gql } from '@apollo/client';

export const taxonomySequenceQueryBuilder = (
  taxSeqQueryName,
  mappingType,
  clientQuery,
  taxPrefix
) => {
  let queryDimensions = '';
  let whereString = '';
  //let orderByString = 'orderBy: { list_value: asc }';
  let argumentsString = '';
  let graphQlQuery = '';

  // taxSeqQueryMeans means one of these (can be found in s2queries.js file)
  // GET_S2_DATADQ_SEQUENCES_STRING
  // GET_S2_DATADQ_SEQUENCES_BY_PREFIX_STRING
  // GET_S2_HIGHEST_DATADQ_SEQUENCE_ID_STRING
  //
  switch (taxSeqQueryName) {
    case 'GET_S2_DATADQ_SEQUENCES': {
      if (clientQuery !== '' /* && clientQuery !== 'Global' */) {
        whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}"}}`;
      } else {
        whereString = `where: {_eq: { mapping_type: "${mappingType}"}}`;
      }
      argumentsString = `(${whereString})`;
      queryDimensions =
        'sequence_id sequence_name sequence_prefix sequence_suffix sequence_numeric sequence_increment sequence_num_of_digits mapping_type created_ts last_updated_ts created_by last_updated_by client_name visible tax_author';
      break;
    }

    case 'GET_S2_HIGHEST_DATADQ_SEQUENCE_ID': {
      argumentsString = `( orderBy: { sequence_id: desc } limit: 1)`;
      queryDimensions = 'sequence_id';
      break;
    }

    case 'GET_S2_DATADQ_SEQUENCES_BY_PREFIX': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}", sequence_prefix: "${taxPrefix}"}`;
      argumentsString = `(${whereString})`;
      queryDimensions =
        'sequence_id sequence_name sequence_prefix sequence_suffix sequence_numeric sequence_increment sequence_num_of_digits mapping_type created_ts last_updated_ts created_by last_updated_by client_name visible tax_author';
      break;
    }

    case 'GET_S2_GLOBAL_DATADQ_SEQUENCES': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}"}}`;
      argumentsString = `(${whereString})`;
      queryDimensions =
        'sequence_id sequence_name sequence_prefix sequence_suffix sequence_numeric sequence_increment sequence_num_of_digits mapping_type created_ts last_updated_ts created_by last_updated_by client_name visible tax_author';
      break;
    }

    case 'GET_S2_CLIENT_DATADQ_SEQUENCES': {
      whereString = `where: { _eq: { mapping_type: "${mappingType}"} _neq: { client_name: "Global"}}`;
      argumentsString = `(${whereString})`;
      queryDimensions =
        'sequence_id sequence_name sequence_prefix sequence_suffix sequence_numeric sequence_increment sequence_num_of_digits mapping_type created_ts last_updated_ts created_by last_updated_by client_name visible tax_author';
      break;
    }

    default:
      break;
  }

  graphQlQuery = gql`
  {
    s2_datadq_sequence ${argumentsString} {
      ${queryDimensions}
    }
  }`;

  return graphQlQuery;
};
