import { useState } from 'react';
import { useSubscription } from '@apollo/client';
import { SUBSCRIBE_S2_USER_NOTIFICATIONS } from 'graphql/hasura/s2queries';
import NotificationsSidebar from './NotificationsSidebar';

const NotificationsSidebarCont = ({ client, login, selectedClientQuery }) => {
  const [hasRefetched, setHasRefetched] = useState(false);

  const { data: subData } = useSubscription(SUBSCRIBE_S2_USER_NOTIFICATIONS, {
    variables: {
      _user: login.username,
      _limit: 999,
      client_name: selectedClientQuery,
    },
    client: client,
  });

  return (
    <div>
      {subData && (
        <NotificationsSidebar
          notificationsUser={subData.user_notifications}
          refetchUserNotifications={() => {}}
          client={client}
          login={login}
          hasRefetched={hasRefetched}
          setHasRefetched={setHasRefetched}
        />
      )}
    </div>
  );
};

export default NotificationsSidebarCont;
