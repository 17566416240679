/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      displayUsername
      firstName
      lastName
      email
      company
      phone
      birthday
      avatar
      slider1
      role
      layout
      report
      lastUsed
      taxonomies
      supportTickets
      bucket
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      displayUsername
      firstName
      lastName
      email
      company
      phone
      birthday
      avatar
      slider1
      role
      layout
      report
      lastUsed
      taxonomies
      supportTickets
      bucket
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      displayUsername
      firstName
      lastName
      email
      company
      phone
      birthday
      avatar
      slider1
      role
      layout
      report
      lastUsed
      taxonomies
      supportTickets
      bucket
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      roleName
      users {
        nextToken
        __typename
      }
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      roleName
      users {
        nextToken
        __typename
      }
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      roleName
      users {
        nextToken
        __typename
      }
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReports = /* GraphQL */ `
  mutation CreateReports(
    $input: CreateReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    createReports(input: $input, condition: $condition) {
      id
      author
      title
      description
      mailingList
      query
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReports = /* GraphQL */ `
  mutation UpdateReports(
    $input: UpdateReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    updateReports(input: $input, condition: $condition) {
      id
      author
      title
      description
      mailingList
      query
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReports = /* GraphQL */ `
  mutation DeleteReports(
    $input: DeleteReportsInput!
    $condition: ModelReportsConditionInput
  ) {
    deleteReports(input: $input, condition: $condition) {
      id
      author
      title
      description
      mailingList
      query
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPublicLayouts = /* GraphQL */ `
  mutation CreatePublicLayouts(
    $input: CreatePublicLayoutsInput!
    $condition: ModelPublicLayoutsConditionInput
  ) {
    createPublicLayouts(input: $input, condition: $condition) {
      id
      author
      title
      description
      layout
      isPublic
      adminUsers
      endUsers
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePublicLayouts = /* GraphQL */ `
  mutation UpdatePublicLayouts(
    $input: UpdatePublicLayoutsInput!
    $condition: ModelPublicLayoutsConditionInput
  ) {
    updatePublicLayouts(input: $input, condition: $condition) {
      id
      author
      title
      description
      layout
      isPublic
      adminUsers
      endUsers
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePublicLayouts = /* GraphQL */ `
  mutation DeletePublicLayouts(
    $input: DeletePublicLayoutsInput!
    $condition: ModelPublicLayoutsConditionInput
  ) {
    deletePublicLayouts(input: $input, condition: $condition) {
      id
      author
      title
      description
      layout
      isPublic
      adminUsers
      endUsers
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      members {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      clientQueryFilters
      layout {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      members {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      clientQueryFilters
      layout {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      members {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      clientQueryFilters
      layout {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserGroups = /* GraphQL */ `
  mutation CreateUserGroups(
    $input: CreateUserGroupsInput!
    $condition: ModelUserGroupsConditionInput
  ) {
    createUserGroups(input: $input, condition: $condition) {
      id
      name
      region
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserGroups = /* GraphQL */ `
  mutation UpdateUserGroups(
    $input: UpdateUserGroupsInput!
    $condition: ModelUserGroupsConditionInput
  ) {
    updateUserGroups(input: $input, condition: $condition) {
      id
      name
      region
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserGroups = /* GraphQL */ `
  mutation DeleteUserGroups(
    $input: DeleteUserGroupsInput!
    $condition: ModelUserGroupsConditionInput
  ) {
    deleteUserGroups(input: $input, condition: $condition) {
      id
      name
      region
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserRegions = /* GraphQL */ `
  mutation CreateUserRegions(
    $input: CreateUserRegionsInput!
    $condition: ModelUserRegionsConditionInput
  ) {
    createUserRegions(input: $input, condition: $condition) {
      id
      name
      clients
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserRegions = /* GraphQL */ `
  mutation UpdateUserRegions(
    $input: UpdateUserRegionsInput!
    $condition: ModelUserRegionsConditionInput
  ) {
    updateUserRegions(input: $input, condition: $condition) {
      id
      name
      clients
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserRegions = /* GraphQL */ `
  mutation DeleteUserRegions(
    $input: DeleteUserRegionsInput!
    $condition: ModelUserRegionsConditionInput
  ) {
    deleteUserRegions(input: $input, condition: $condition) {
      id
      name
      clients
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
