import { gql } from '@apollo/client';

export const taxonomyQueryBuilder = (
  taxQueryName,
  mappingType,
  clientQuery,
  taxPrefix
) => {
  let queryDimensions = '';
  let whereString = '';
  //let orderByString = 'orderBy: { list_value: asc }';
  let argumentsString = '';
  let graphQlQuery = '';

  // taxQueryMeans means one of these (can be found in s2queries.js file)
  // GET_S2_TAXONOMIES_STRING
  // GET_S2_TAXONOMY_INDEX_HIGHEST_STRING
  // GET_S2_TAXONOMY_BY_PREFIX_STRING
  //
  switch (taxQueryName) {
    case 'GET_S2_TAXONOMIES': {
      if (clientQuery !== '' && clientQuery !== 'Global') {
        whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}"}}`;
      } else {
        whereString = `where: {_eq: { mapping_type: "${mappingType}"}}`;
      }
      argumentsString = `(${whereString})`;
      queryDimensions =
        'taxonomy_string taxonomy_name taxonomy_id taxonomy_description taxonomy_prefix mapping_type last_updated_ts last_updated_by created_ts created_by client_name visible tax_author';
      break;
    }
    case 'GET_S2_TAXONOMY_INDEX_HIGHEST': {
      argumentsString = `( orderBy: { taxonomy_id: desc } limit: 1)`;
      queryDimensions = 'taxonomy_id';
      break;
    }

    case 'GET_S2_TAXONOMY_BY_PREFIX': {
      whereString = `where: {_eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}", taxonomy_prefix: ${taxPrefix}}`;
      argumentsString = `(${whereString})`;
      queryDimensions =
        'taxonomy_string taxonomy_name taxonomy_id taxonomy_description taxonomy_prefix mapping_type last_updated_ts last_updated_by created_ts created_by client_name visible tax_author';
      break;
    }

    case 'GET_S2_GLOBAL_TAXONOMIES': {
      whereString = `where: { _eq: { mapping_type: "${mappingType}", client_name: "${clientQuery}"}}`;
      argumentsString = `(${whereString})`;
      queryDimensions =
        'taxonomy_string taxonomy_name taxonomy_id taxonomy_description taxonomy_prefix mapping_type last_updated_ts last_updated_by created_ts created_by client_name visible tax_author';
      break;
    }

    case 'GET_S2_CLIENT_TAXONOMIES': {
      whereString = `where: { _eq: { mapping_type: "${mappingType}"} _neq: { client_name: "Global"}}`;
      argumentsString = `(${whereString})`;
      queryDimensions =
        'taxonomy_string taxonomy_name taxonomy_id taxonomy_description taxonomy_prefix mapping_type last_updated_ts last_updated_by created_ts created_by client_name visible tax_author';
      break;
    }

    default:
      break;
  }

  /* console.log(clientQuery);
  console.log(queryDimensions);
  console.log(taxQueryName); */
  graphQlQuery = gql`
  {
    s2_taxonomies ${argumentsString} {
      ${queryDimensions}
    }
  }`;

  return graphQlQuery;
};
