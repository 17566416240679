import { createSlice } from '@reduxjs/toolkit';
import { initialMappingMode } from 'redux/reduxInitialStates';

const initialState = initialMappingMode;

export const mappingModeSlice = createSlice({
  name: 'mappingMode',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateMappingModeVal: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { revertToInitialState, updateMappingModeVal } =
  mappingModeSlice.actions;

export const selectMappingMode = (state) => {
  return state.mappingMode;
};

export default mappingModeSlice.reducer;
