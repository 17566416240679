import React from 'react';

var initalLoginState = {
  displayUsername: '',
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  phone: '',
  birthday: '',
  authCode: '',
  formType: 'wait',
  avatar: '/img/avatars/profiles/avatar-white.png',
  role: '',
  company: '',
  permissions: [],
  layout: '',
  lastUsed: '',
  regionRefresh: null,
};

const AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  const [login, setLogin] = React.useState(initalLoginState);

  return (
    <AuthContext.Provider value={{ login, setLogin }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
