import { createSlice } from '@reduxjs/toolkit';
import { initialVerifiedRender } from 'redux/reduxInitialStates';

const initialState = initialVerifiedRender;

export const verifiedSlice = createSlice({
  name: 'verifiedObj',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateVerified: (state, action) => {
      state.verified = action.payload;
    },
  },
});

export const { revertToInitialState, updateVerified } = verifiedSlice.actions;

export const selectVerified = (state) => {
  return state.verified;
};

export default verifiedSlice.reducer;
