import { createSlice } from "@reduxjs/toolkit";
import { initialMappingQuerySetupVal } from "redux/reduxInitialStates";

const initialState = initialMappingQuerySetupVal;

export const mappingQuerySetupSlice = createSlice({
  name: "mappingQuerySetup",
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateMappingQueryPlacementSetup: (state, action) => {
      state.placement_setup = action.payload;
    },
    updateMappingQueryCreativeSetup: (state, action) => {
      state.creative_setup = action.payload;
    },
  },
});

export const {
  revertToInitialState,
  updateMappingQueryPlacementSetup,
  updateMappingQueryCreativeSetup,
} = mappingQuerySetupSlice.actions;

export const selectMappingQuerySetup = (state) => {
  return state.mappingQuerySetup;
};

export default mappingQuerySetupSlice.reducer;
