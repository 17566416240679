import { format } from 'date-fns';
import CheckIcon from '../../components/shared/icons/CheckIcon';
import PlayIcon from '../../components/shared/icons/PlayIcon';

const NotificationsSidebarUnreadList = ({
  notification,
  markItemAsRead,
  isRead,
}) => {
  return (
    <div
      key={notification.notification_id}
      style={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        cursor: 'pointer',
      }}
      className='notification-item'
      onClick={() => {
        markItemAsRead(notification.notification_id);
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {' '}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.375rem',
          }}
        >
          {notification.status === 'COMPLETE' ? (
            <CheckIcon width='16' height='16' stroke='#BDBDBD' />
          ) : (
            <PlayIcon
              width='16'
              height='16'
              stroke='var(--intuita-main)'
              fill='var(--intuita-main)'
            />
          )}

          <h4
            style={{
              marginBottom: 0,
              fontWeight: !isRead && '700',
            }}
          >
            {notification.list_name}
          </h4>
        </div>
        <span
          style={{
            fontSize: '12px',
            fontWeight: !isRead && '700',
          }}
        >
          {format(new Date(notification.created_ts), 'dd-MM-yyy HH:mm:ss')}
        </span>
      </div>

      <p
        style={{
          marginBottom: 0,
          fontWeight: !isRead && '700',
        }}
      >
        {notification.notification_text}
      </p>

      <hr className='my-0' />
    </div>
  );
};

export default NotificationsSidebarUnreadList;
