import { gql } from '@apollo/client';

export const INSERT_S2_TAXONOMY = gql`
  mutation S2_insert_new_taxonomy($object: S2TaxonomyObj!) {
    s2_insert_new_taxonomy(object: $object) {
      taxonomy_string
      taxonomy_name
      taxonomy_id
      taxonomy_description
      taxonomy_prefix
      mapping_type
      last_updated_ts
      last_updated_by
      created_ts
      created_by
      client_name
      tax_author
      visible
    }
  }
`;

export const UPDATE_S2_TAXONOMY = gql`
  mutation S2_update_taxonomy(
    $object: S2TaxonomyObj
    $where: S2TaxonomyWhereInput
  ) {
    s2_update_taxonomy(object: $object, where: $where) {
      taxonomy_id
      taxonomy_name
      taxonomy_description
      taxonomy_string
      taxonomy_prefix
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      client_name
      tax_author
      visible
    }
  }
`;

export const INSERT_NEW_DATADQ_SEQUENCE = gql`
  mutation S2_insert_data_sequence($object: S2DatadqSequenceObj) {
    s2_insert_new_datadq_sequence(object: $object) {
      sequence_id
      sequence_name
      sequence_prefix
      sequence_suffix
      sequence_numeric
      sequence_increment
      sequence_num_of_digits
      mapping_type
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      client_name
      tax_author
    }
  }
`;

export const UPDATE_S2_DATADQ_SEQUENCE = gql`
  mutation S2_update_datadq_sequence(
    $object: S2DatadqSequenceObj
    $where: S2DatadqSequenceWhereInput
  ) {
    s2_update_data_sequence(object: $object, where: $where) {
      sequence_name
      sequence_id
      sequence_prefix
      sequence_suffix
      sequence_numeric
      sequence_increment
      sequence_num_of_digits
      mapping_type
      created_ts
      created_by
      last_updated_by
      last_updated_ts
      client_name
      tax_author
    }
  }
`;

export const SET_NOTIFICATIONS_READ = gql`
  mutation Set_notifications_read(
    $object: live_notification_read_insert_input!
  ) {
    insert_live_notification_read_one(object: $object) {
      notification_id
      notification_user
      notification_read_dts
    }
  }
`;

export const SET_NOTIFICATIONS_CLEARED = gql`
  mutation Set_notifications_cleared(
    $object: live_notification_read_insert_input!
  ) {
    insert_live_notification_read_one(object: $object) {
      notification_id
      notification_user
      notification_read_dts
      status
    }
  }
`;

export const SET_LIST = gql`
  mutation Set_lists($list: [S2ListObj!]!) {
    set_lists(list: $list) {
      list_id
      list_value
      list_alt_value
      list_name
      sequence_no
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      mapping_type
      mapping_id
      table_id
      visible
      item_author
      list_author
      uses
      removed
      is_core_item
      item_desc
      list_desc
      client_name
      user_defined_columns {
        name
        type
        value
        column_id
        column_type_id
        branch_id
        cell_id
      }
    }
  }
`;

export const SET_RELATIONSHIPS = gql`
  mutation Set_relationships($relationship: [S2RelationshipObj!]!) {
    set_relationships(relationship: $relationship) {
      client_name
      column_name
      column_type
      default_value
      display_name
      user_defined_name
      is_editable
      is_mandatory
      is_visible
      can_override_list
      list_name
      mapping_type
      table_id
      row_id
      created_ts
      last_updated_ts
      created_by
      last_updated_by
      sequence_no
      visible
      no_blanks
      max_char_length
      distinct
      is_number
      exceptions
    }
  }
`;

export const SET_MAPPINGS = gql`
  mutation Set_mappings($array: [S2Mapping2Obj]) {
    set_mappings_2(array: $array) {
      mapping_id
    }
  }
`;

export const SET_COLUMN_WIDTH = gql`
  mutation Set_column_width(
    $mappingType: String
    $tableType: String
    $clientName: String
    $columnWidths: [InDataDQColumn]
  ) {
    set_column_width(
      mapping_type: $mappingType
      table_type: $tableType
      client_name: $clientName
      column_widths: $columnWidths
    ) {
      column
      width
      cell_id
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation Delete_client($clientName: String!) {
    delete_client(client_name: $clientName)
  }
`;
