export default function CheckIcon({ ...props }) {
  return (
    <svg
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox='0 0 24 24'
      fill={props.fill || 'none'}
      style={props.style}
      className={props.className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 6L9 17L4 12'
        stroke={props.stroke || '#F2F2F2'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
