import { createSlice } from "@reduxjs/toolkit";
import { initialPlacementMapping } from "redux/reduxInitialStates";

const initialState = initialPlacementMapping;

export const placementMappingSlice = createSlice({
  name: "placementMapping",
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updatePlacementMapping: (state, action) => {
      state.mainState = action.payload;
    },
  },
});

export const { revertToInitialState, updatePlacementMapping } =
  placementMappingSlice.actions;

export const selectPlacementMapping = (state) => {
  return state.placementMapping;
};

export default placementMappingSlice.reducer;
