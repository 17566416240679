/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($username: String!) {
    getUser(username: $username) {
      id
      username
      displayUsername
      firstName
      lastName
      email
      company
      phone
      birthday
      avatar
      slider1
      role
      layout
      report
      lastUsed
      taxonomies
      supportTickets
      bucket
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $username: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        username
        displayUsername
        firstName
        lastName
        email
        company
        phone
        birthday
        avatar
        slider1
        role
        layout
        report
        lastUsed
        taxonomies
        supportTickets
        bucket
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($roleName: String!) {
    getRole(roleName: $roleName) {
      id
      roleName
      users {
        nextToken
        __typename
      }
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $roleName: String
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoles(
      roleName: $roleName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        roleName
        company
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReports = /* GraphQL */ `
  query GetReports($id: ID!) {
    getReports(id: $id) {
      id
      author
      title
      description
      mailingList
      query
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $filter: ModelReportsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        title
        description
        mailingList
        query
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublicLayouts = /* GraphQL */ `
  query GetPublicLayouts($id: ID!) {
    getPublicLayouts(id: $id) {
      id
      author
      title
      description
      layout
      isPublic
      adminUsers
      endUsers
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPublicLayouts = /* GraphQL */ `
  query ListPublicLayouts(
    $filter: ModelPublicLayoutsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicLayouts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        title
        description
        layout
        isPublic
        adminUsers
        endUsers
        company
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($name: String!) {
    getCompany(name: $name) {
      id
      name
      members {
        nextToken
        __typename
      }
      roles {
        nextToken
        __typename
      }
      clientQueryFilters
      layout {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $name: String
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        clientQueryFilters
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserGroups = /* GraphQL */ `
  query GetUserGroups($name: String!) {
    getUserGroups(name: $name) {
      id
      name
      region
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserGroups = /* GraphQL */ `
  query ListUserGroups(
    $name: String
    $filter: ModelUserGroupsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserGroups(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        region
        members
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserRegions = /* GraphQL */ `
  query GetUserRegions($name: String!) {
    getUserRegions(name: $name) {
      id
      name
      clients
      members
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserRegions = /* GraphQL */ `
  query ListUserRegions(
    $name: String
    $filter: ModelUserRegionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserRegions(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        clients
        members
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
