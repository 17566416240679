import { createSlice } from '@reduxjs/toolkit';
import { initialManageListsMenuVal } from 'redux/reduxInitialStates';

const initialState = initialManageListsMenuVal;

export const listMenuSlice = createSlice({
  name: 'listMenuVal',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateListMenuVal: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const { revertToInitialState, updateListMenuVal } =
  listMenuSlice.actions;

export const selectListMenuVal = (state) => {
  return state.listMenuVal;
};

export default listMenuSlice.reducer;
