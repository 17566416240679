import { configureStore } from '@reduxjs/toolkit';
import tableQueryReducer from './slices/table/tableQuerySlice';
import reportTableQueryReducer from './slices/table/reportTableQuerySlice';

import graphQueryReducer from './slices/graph/graphQuery.slice.js';

import sumQueryReducer from './slices/sum/sumQuery.slice.js';
import mappingTypeReducer from './slices/mappingType/mappingType.slice';
import verifiedReducer from './slices/verified/verified.slice';
import listMenuReducer from './slices/listMenu/listMenu.slice';
import creativeMappingReducer from './slices/s2Mappings/creativeMapping.slice';
import placementMappingReducer from './slices/s2Mappings/placementMapping.slice';
import mappingQuerySetupReducer from './slices/s2Mappings/mappingQuerySetup.slice';
import mappingModeReducer from './slices/mappingMode/mappingMode.slice';
import chartConfigStateReducer from './slices/chartConfigState/chartConfigStateSlice.slice';

import dataHealthGraphQueryReducer from 'redux/slices/graph/dataHealthGraphQuerySlice.slice';
import complianceQueryReducer from 'redux/slices/graph/complianceQuerySlice.slice';
import currencyReducer from 'redux/slices/currency/currencySlice.slice';

export default configureStore({
  reducer: {
    tableQuery: tableQueryReducer,
    reportTable: reportTableQueryReducer,
    graphQuery: graphQueryReducer,
    dataHealthGraphQuery: dataHealthGraphQueryReducer,
    sumQuery: sumQueryReducer,
    mappingType: mappingTypeReducer,
    verified: verifiedReducer,
    listMenuVal: listMenuReducer,
    creativeMapping: creativeMappingReducer,
    placementMapping: placementMappingReducer,
    mappingQuerySetup: mappingQuerySetupReducer,
    mappingMode: mappingModeReducer,
    complianceQuery: complianceQueryReducer,
    currency: currencyReducer,
    chart: chartConfigStateReducer,
  },
});
