import { createSlice } from '@reduxjs/toolkit';
import { initialTableQuery } from 'redux/reduxInitialStates';

const initialState = initialTableQuery;

export const tableQuerySlice = createSlice({
  name: 'tableQuery',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateTableDimension: (state, action) => {
      state.dimensions = action.payload;
    },
    updateTableMeasures: (state, action) => {
      state.measures = action.payload;
    },
    updateTableOrder: (state, action) => {
      state.order = [action.payload];
    },
    updateTableFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateTableTimeDimensionTimeRange: (state, action) => {
      state.period = action.payload;
    },
    updateTableTimeDimensionGranularity: (state, action) => {
      state.granularity = action.payload;
    },
  },
});

export const {
  revertToInitialState,
  updateTableDimension,
  updateTableMeasures,
  updateTableFilters,
  updateTableOrder,
  updateTableTimeDimensionGranularity,
  updateTableTimeDimensionTimeRange,
} = tableQuerySlice.actions;

export const selectTableQuery = (state) => {
  return state.tableQuery;
};

export default tableQuerySlice.reducer;
