import { createSlice } from '@reduxjs/toolkit';
import { initialMappingTypeVal } from 'redux/reduxInitialStates';

const initialState = initialMappingTypeVal;

export const mappingTypeSlice = createSlice({
  name: 'mappingType',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateMappingType: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const { revertToInitialState, updateMappingType } =
  mappingTypeSlice.actions;

export const selectMappingType = (state) => {
  return state.mappingType;
};

export default mappingTypeSlice.reducer;
