import { createSlice } from "@reduxjs/toolkit";
import { initialCreativeMapping } from "redux/reduxInitialStates";

const initialState = initialCreativeMapping;

export const creativeMappingSlice = createSlice({
  name: "creativeMapping",
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateCreativeMapping: (state, action) => {
      state.mainState = action.payload;
    },
  },
});

export const { revertToInitialState, updateCreativeMapping } =
  creativeMappingSlice.actions;

export const selectCreativeMapping = (state) => {
  return state.creativeMapping;
};

export default creativeMappingSlice.reducer;
