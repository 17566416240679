import { createUser, updateUser } from '../graphql/mutations';
import {
  getUser,
  listUsers,
  listRoles,
  listCompanies,
  getCompany,
  listPublicLayouts,
} from '../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from 'aws-amplify/auth';
import { getUrl } from 'aws-amplify/storage';
import { format } from 'date-fns';

const client = generateClient();

export const storeUser = async (user) => {
  let userCase = {
    ...user,
    username: user.username.toLowerCase(),
    displayUsername: user.username,
  };
  try {
    await client.graphql({
      query: createUser,
      variables: { input: { ...userCase } },
    });
  } catch (err) {
    console.error(err);
  }
};

export const fetchAvatarKey = async (user) => {
  const person = await client.graphql({
    query: getUser,
    variables: { input: { username: user } },
  });
  const key = person.data.getUser.avatar;
  if (key) return key;
  return false;
};

export const updateSlider = async (name, value) => {
  try {
    const userDetails = {
      username: name,
      slider1: value,
    };
    await client.graphql({
      query: updateUser,
      variables: { input: userDetails },
    });
  } catch (err) {}
};

export const getSliderValue = async (name) => {
  try {
    const person = await client.graphql({
      query: getUser,
      variables: { input: { username: name } },
    });

    return person.data.getUser.slider1;
  } catch (err) {}
};

export const storeAvatar = async (user, avatar) => {
  const username = user;
  var payload = { username, avatar };
  await client.graphql({
    query: getUser,
    variables: { input: { username: user } },
  });
  await client.graphql({ query: updateUser, variables: { input: payload } });
};

export const getAvatarList = (imageKeys) => {
  var promisesOfS3Objects = imageKeys.map(function (key) {
    return new Promise((Resolve, Reject) => {
      try {
        const img = getUrl({ key: key });
        Resolve(img);
      } catch (err) {
        Reject(err);
      }
    });
  });
  return Promise.all(promisesOfS3Objects);
};

//Auth Check
export async function authCurrentUser() {
  try {
    return await getCurrentUser({
      bypassCache: true,
    });
  } catch (err) {
    console.error('Error at [dataFetch.js][authCurrentUser]: ', err);
  }
}

//Last Activity
export const storeActivity = async (login) => {
  let date = Date.now();
  const formatDate = format(new Date(date), 'MM/dd/yyyy - HH:mm');
  try {
    const userDetails = {
      username: login.username,
      lastUsed: formatDate,
    };

    await client.graphql({
      query: updateUser,
      variables: { input: userDetails },
    });
  } catch (err) {
    console.error(err?.errors[0].message);
  }
};

export async function getUserData(username) {
  try {
    let user = await client.graphql({
      query: getUser,
      variables: { username: username },
    });
    return user.data.getUser;
  } catch (err) {
    console.error('Error at [App.js][checkForRole]: ', err);
    return null;
  }
}

//List Roles Permissions and Users
export const fetchListOfUsers = async () => {
  try {
    const userData = await client.graphql({
      query: listUsers,
    });
    const usersList = userData.data.listUsers.items;
    return usersList;
  } catch (error) {
    console.error(error);
    return 'Can not Get Users';
  }
};

export const fetchListOfRoles = async () => {
  try {
    const roleData = await client.graphql({
      query: listRoles,
    });
    const rolesList = roleData.data.listRoles.items;
    return rolesList;
  } catch (error) {
    console.error(error);
    return 'Cannot Get Role';
  }
};

export const fetchListOfCompanies = async () => {
  try {
    const companyData = await client.graphql({
      query: listCompanies,
    });
    const companiesList = companyData.data.listCompanies.items;
    return companiesList;
  } catch (error) {
    console.error(error);
    return 'Cannot Get Company';
  }
};

export const fetchCompany = async (company) => {
  try {
    const companyData = await client.graphql({
      query: getCompany,
      variables: { input: { name: company } },
    });
    const companyList = companyData.data.getCompany;
    return companyList;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const fetchListOfLayouts = async () => {
  try {
    const publicLayoutsData = await client.graphql({
      query: listPublicLayouts,
    });
    const publicLayoutsList = publicLayoutsData.data.listPublicLayouts.items;

    return publicLayoutsList;
  } catch (error) {
    console.error(error);
    return error;
  }
};
