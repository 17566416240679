import { format } from 'date-fns';
import CheckIcon from '../../components/shared/icons/CheckIcon';

const NotificationsSidebarReadList = ({ notification }) => {
  return (
    <div
      key={notification.notification_id}
      style={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        cursor: 'pointer',
      }}
      className='notification-item'
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {' '}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.375rem',
          }}
        >
          <CheckIcon width='16' height='16' stroke='#BDBDBD' />
          <h4
            style={{
              marginBottom: 0,
            }}
          >
            {notification.list_name}
          </h4>
        </div>
        <span
          style={{
            fontSize: '12px',
          }}
        >
          {format(new Date(notification.last_updated_ts), 'dd-MM-yyy HH:mm:ss')}
        </span>
      </div>

      <p
        style={{
          marginBottom: 0,
        }}
      >
        {notification.notification_text}
      </p>

      <hr className='my-0' />
    </div>
  );
};

export default NotificationsSidebarReadList;
