import { createSlice } from '@reduxjs/toolkit';
import { initialGraphQuery } from 'redux/reduxInitialStates';

const initialState = initialGraphQuery;

export const graphQuerySlice = createSlice({
  name: 'graphQuery',
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateGraphMeasures: (state, action) => {
      state.measures = action.payload;
    },
    updateGraphDimension: (state, action) => {
      state.dimensions = action.payload;
    },
    updateGraphOrder: (state, action) => {
      if (action.payload.length > 1) {
        state.order = action.payload;
      } else {
        state.order = [action.payload];
      }
    },
    updateGraphTimeDimensionTimeRange: (state, action) => {
      state.period = action.payload;
    },
    updateGraphTimeDimensionGranularity: (state, action) => {
      state.granularity = action.payload;
    },
    updateGraphFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

/* updateGraphTimeDimensionTimeRange: (state, action) => {
  state.timeDimensions[0].dateRange = action.payload;
},
updateGraphTimeDimensionGranularity: (state, action) => {
  state.timeDimensions[0].granularity = action.payload;
}, */

export const {
  revertToInitialState,
  updateGraphDimension,
  updateGraphFilters,
  updateGraphOrder,
  updateGraphTimeDimensionGranularity,
  updateGraphTimeDimensionTimeRange,
  updateGraphMeasures,
} = graphQuerySlice.actions;

export const selectGraphQuery = (state) => {
  return state.graphQuery;
};

export default graphQuerySlice.reducer;
