import { Link } from 'react-router-dom';
import SettingsIcon from '../../components/shared/icons/SettingsIcon';

const SettingsDropdown = ({
  login,
  navData,
  isSettingsOpen,
  setIsSettingsOpen,
  showImportCsvModal,
  settingsDropdownRef,
  setShowImportCsvModal,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <div className='dd' ref={settingsDropdownRef}>
        <div
          className='dd-setup_toggle'
          onClick={() => {
            setIsSettingsOpen(!isSettingsOpen);
          }}
        >
          <div
            className='h-100'
            style={{
              background: isSettingsOpen ? '#232751' : null,
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              padding: '.5rem',
            }}
          >
            <span
              className='dd-setup_label'
              style={{
                color: 'white',
                fontWeight: isSettingsOpen ? 'bold' : null,
              }}
            >
              <SettingsIcon />
            </span>
          </div>
        </div>
        {isSettingsOpen && (
          <div className='dd-setup_menu overflow-style' style={{ zIndex: 999 }}>
            {navData?.clientManagement?.readPermissions.includes(
              login.permissions.find((perm) => {
                return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
              })
            ) && (
              <Link
                onClick={() => {
                  if (showImportCsvModal) setShowImportCsvModal(false);
                  if (isSettingsOpen === true) {
                    setIsSettingsOpen(false);
                  }
                }}
                className='dd-setup_item'
                to='/admin/user-management'
              >
                Manage Users
              </Link>
            )}
            {navData?.account?.readPermissions.includes(
              login.permissions.find((perm) => {
                return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
              })
            ) && (
              <Link
                onClick={() => {
                  if (showImportCsvModal) setShowImportCsvModal(false);
                  if (isSettingsOpen === true) {
                    setIsSettingsOpen(false);
                  }
                }}
                className='dd-setup_item'
                to={`/profile/${login.firstName + login.lastName}`}
              >
                User Profile
              </Link>
            )}
            {navData?.audit?.readPermissions.includes(
              login.permissions.find((perm) => {
                return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
              })
            ) && (
              <Link
                onClick={() => {
                  if (showImportCsvModal) setShowImportCsvModal(false);
                  if (isSettingsOpen === true) {
                    setIsSettingsOpen(false);
                  }
                }}
                className='dd-setup_item'
                to='/audit'
              >
                History Log
              </Link>
            )}

            {navData?.support?.readPermissions.includes(
              login.permissions.find((perm) => {
                return perm.startsWith('Role.') || perm.startsWith('eu-west-2');
              })
            ) && (
              <Link
                onClick={() => {
                  if (showImportCsvModal) setShowImportCsvModal(false);
                  if (isSettingsOpen === true) {
                    setIsSettingsOpen(false);
                  }
                }}
                className='dd-setup_item'
                to='/support-center'
              >
                Support
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsDropdown;
