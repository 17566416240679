const NotificationsSidebarTabSwitcher = ({
  notificationsState,
  setNotificationsState,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        padding: '1rem',
        paddingBottom: 0,
        borderBottom: '1px solid #E0E0E0',
      }}
    >
      {/* NEW */}
      <div
        style={{
          border: '1px solid #E0E0E0',
          borderBottom:
            notificationsState === 'New' ? '4px solid var(--intuita-main)' : '',
          padding: '0.5rem 2rem',
          cursor: 'pointer',
        }}
        onClick={() => {
          setNotificationsState('New');
        }}
      >
        <span
          style={{
            fontWeight: '700',
            fontSize: '0.875rem',
            lineHeight: '21px',
            textTransform: 'uppercase',
            letterSpacing: '0.08em',
            color:
              notificationsState === 'New' ? 'var(--intuita-main)' : '#828282',
          }}
        >
          {' '}
          New{' '}
        </span>
      </div>

      {/* READ */}
      <div
        style={{
          border: '1px solid #E0E0E0',
          borderBottom:
            notificationsState === 'Cleared'
              ? '4px solid var(--intuita-main)'
              : '',
          padding: '0.5rem 2rem',
          cursor: 'pointer',
        }}
        onClick={() => {
          setNotificationsState('Cleared');
        }}
      >
        <span
          style={{
            fontWeight: '700',
            fontSize: '0.875rem',
            lineHeight: '21px',
            textTransform: 'uppercase',
            letterSpacing: '0.08em',
            color:
              notificationsState === 'Cleared'
                ? 'var(--intuita-main)'
                : '#828282',
          }}
        >
          {' '}
          Cleared{' '}
        </span>
      </div>
    </div>
  );
};

export default NotificationsSidebarTabSwitcher;
