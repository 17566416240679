import Tooltip from '../../components/shared/tooltip/Tooltip';
import { FaUser, FaUsers } from 'react-icons/fa6';
import { MdClearAll, MdMarkEmailRead } from 'react-icons/md';

const NotificationsSidebarHeader = ({
  curUserNotifications,
  isCurrentUserNotifications,
  markItemsAsRead,
  markItemsAsCleared,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem',
      }}
    >
      <h2
        style={{
          marginBottom: 0,
          fontWeight: '700',
        }}
      >
        Notifications
      </h2>

      <div style={{ display: 'flex', gap: '0.75rem' }}>
        {' '}
        {curUserNotifications ? (
          <Tooltip
            content='You are viewing your notifications only. Click to view all.'
            placement='left'
          >
            <span>
              <FaUser
                size={20}
                className='cursor-pointer'
                onClick={isCurrentUserNotifications}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            content='You are viewing all notifications. Click to view yours only.'
            placement='left'
          >
            <span>
              <FaUsers
                size={24}
                className='cursor-pointer'
                onClick={isCurrentUserNotifications}
              />{' '}
            </span>
          </Tooltip>
        )}
        <Tooltip
          content='Mark all as read'
          placement='left'
          trigger='mouseenter'
        >
          <span>
            <MdMarkEmailRead
              size={24}
              className='cursor-pointer'
              onClick={() => {
                markItemsAsRead();
              }}
            />
          </span>
        </Tooltip>
        <Tooltip
          content='Mark all as cleared'
          placement='left'
          trigger='mouseenter'
        >
          <span>
            <MdClearAll
              size={24}
              className='cursor-pointer'
              onClick={() => {
                markItemsAsCleared();
              }}
            />
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default NotificationsSidebarHeader;
