import { createSlice } from '@reduxjs/toolkit';
import { initialTableQuery } from 'redux/reduxInitialStates';

const initialState = initialTableQuery;

export const reportTableQuerySlice = createSlice({
  name: 'tableQuery',
  initialState,
  reducers: {
    revertReportTableToInitialState: () => {
      return initialState;
    },
    updateReportTableDimension: (state, action) => {
      state.dimensions = action.payload;
    },
    updateReportTableMeasures: (state, action) => {
      state.measures = action.payload;
    },
    updateReportTableOrder: (state, action) => {
      state.order = [action.payload];
    },
    updateReportTableFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateReportTableTimeDimensionTimeRange: (state, action) => {
      state.period = action.payload;
    },
    updateReportTableTimeDimensionGranularity: (state, action) => {
      state.granularity = action.payload;
    },
  },
});

export const {
  revertReportTableToInitialState,
  updateReportTableDimension,
  updateReportTableMeasures,
  updateReportTableFilters,
  updateReportTableOrder,
  updateReportTableTimeDimensionGranularity,
  updateReportTableTimeDimensionTimeRange,
} = reportTableQuerySlice.actions;

export const selectTableQuery = (state) => {
  return state.tableQuery;
};

export default reportTableQuerySlice.reducer;
