import { createSlice } from "@reduxjs/toolkit";
import { initialComplianceScoreQuery } from "../../reduxInitialStates";

const initialState = initialComplianceScoreQuery;

export const complianceGraphQuerySlice = createSlice({
  name: "complianceQuery",
  initialState,
  reducers: {
    revertToInitialState: () => {
      return initialState;
    },
    updateGraphMeasures: (state, action) => {
      state.measures = action.payload;
    },
    updateGraphDimension: (state, action) => {
      state.dimensions = action.payload;
    },
    updateGraphOrder: (state, action) => {
      if (action.payload.length > 1) {
        state.order = action.payload;
      } else {
        state.order = [action.payload];
      }
    },
    updateGraphTimeDimensionTimeRange: (state, action) => {
      state.period = action.payload;
    },
    updateGraphTimeDimensionGranularity: (state, action) => {
      state.granularity = action.payload;
    },
    updateGraphFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

/* updateGraphTimeDimensionTimeRange: (state, action) => {
  state.timeDimensions[0].dateRange = action.payload;
},
updateGraphTimeDimensionGranularity: (state, action) => {
  state.timeDimensions[0].granularity = action.payload;
}, */

export const {
  revertToInitialState,
  updateGraphDimension,
  updateGraphFilters,
  updateGraphOrder,
  updateGraphTimeDimensionGranularity,
  updateGraphTimeDimensionTimeRange,
  updateGraphMeasures,
} = complianceGraphQuerySlice.actions;

export const selectComplianceGraphQuery = (state) => {
  return state.complianceQuery;
};

export default complianceGraphQuerySlice.reducer;
