import React from 'react';

export const QueryContext = React.createContext(null);

const initalState = {
  measures: ['SourceUsage.impressions'],
  timeDimensions: [
    {
      dimension: 'SourceUsage.date',
      granularity: 'month',
      dateRange: 'This year',
    },
  ],
  order: [['SourceUsage.sourceSystem', 'asc']],
  dimensions: ['SourceUsage.sourceSystem'],
  filters: [
    {
      member: 'SourceUsage.sourceSystem',
      operator: 'contains',
      values: [],
    },
    {
      member: 'SourceUsage.platform',
      operator: 'contains',
      values: [],
    },
    {
      member: 'SourceUsage.device',
      operator: 'contains',
      values: [],
    },
    {
      member: 'SourceUsage.customerName',
      operator: 'contains',
      values: [],
    },
  ],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'REPORT_BREAKDOWN':
      return {
        ...state,
        timeDimensions: [
          {
            ...state.timeDimensions[0],
            granularity: action.payload.granularity,
          },
        ],
      };
    case 'REPORT_DIMENSION':
      return {
        ...state,
        dimensions: action.payload.dimension,
        order: [action.payload.order],
      };
    case 'TOTALS_FOR_PERIOD':
      return {
        ...state,
        measures: action.payload.measures,
      };
    case 'TIME_PERIOD':
      return {
        ...state,
        timeDimensions: [
          {
            ...state.timeDimensions[0],
            dateRange: action.payload.dateRange,
          },
        ],
      };
    case 'FILTERS':
      return {
        ...state,
        filters: action.payload.filters,
      };
    default:
      throw new Error();
  }
};

function QueryProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initalState);

  const memoizedValue = React.useMemo(() => [state, dispatch], [state]);

  return (
    <QueryContext.Provider value={memoizedValue}>
      {children}
    </QueryContext.Provider>
  );
}

export default QueryProvider;
